<template>
  <div class="page-content">
    <page-breadcrumb title="Activation" />
    <b-media>
      <section class="py-3">
        <b-row>
          <b-col md="10">
            <h2>Activation Management</h2>
          </b-col>
        </b-row>
      </section>
      <b-card>
        <form-activation @activation="activation" :is-loading="isLoading" />
      </b-card>
    </b-media>
  </div>
</template>

<script>
import service from "../service";
import FormActivation from "./_components/FormActivation.vue";

export default {
  components: {
    FormActivation,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  methods: {
    async activation({ email, phone, active_code }) {
      this.isLoading = true;
      let response = await service.activationLearn({
        email,
        phone,
        active_code,
      });
      if (response?.data?.data?.status) {
        this.$store.dispatch("pushSuccessNotify", {
          text: "Activation success!",
        });
      }
      this.isLoading = false;
    },
  },
};
</script>
