<template>
  <b-form @submit.prevent="submit">
    <b-row>
      <b-col cols="12">
        <b-form-group label="Email" label-for="hi-email" label-cols-md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="MailIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="info_activation.email"
              id="hi-email"
              type="email"
              placeholder="Email"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Phone" label-for="hi-number" label-cols-md="3">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SmartphoneIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="info_activation.phone"
              id="hi-number"
              type="number"
              placeholder="Phone"
            />
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group
          label="Active Code"
          label-for="hi-active_code"
          label-cols-md="3"
        >
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="KeyIcon" />
            </b-input-group-prepend>
            <b-form-input
              v-model="info_activation.active_code"
              id="hi-code"
              type="text"
              placeholder="Active Code"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <!-- submit-->
      <b-col class="text-center">
        <b-button type="submit" variant="primary" class="mt-2">
          <b-spinner v-if="isLoading" small />
          <template v-else> Activation </template>
        </b-button>
      </b-col>
    </b-row>
  </b-form>
</template>

<script>
export default {
  props: {
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      info_activation: {
        email: null,
        phone: null,
        active_code: null,
      },
    };
  },
  methods: {
    submit() {
      if (this.info_activation.email == "") delete this.info_activation.email;
      this.$emit("activation", this.info_activation);
    },
  },
};
</script>

